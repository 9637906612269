<template>
  <div class="menu">
    <div
      v-for="(option, index) of options"
      :key="index"
      class="menu__option"
      :class="{
        'menu__option--active': isSelectedOption(index),
        live__option: option.componentName == 'Live' && showLive,
      }"
      @click="onOptionClick(option, index)"
    >
      <div class="menu__option__precision" v-if="option.precision">{{ option.precision }}</div>
      <template v-if="option.componentName === 'Live'">
        <template v-if="showLive">
          <div class="menu__option__icon" :class="!showLive ? 'opacityIcon' : ''">
            <img :src="`${option.icon}.svg`" />
          </div>
        </template>
        <template v-else>
          <div class="menu__option__icon" :class="!showLive ? 'opacityIcon' : ''">
            <img :src="`/icons/icn_live_option_white.svg`" />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="menu__option__icon">
          <img :src="`${option.icon}_${isSelectedOption(index) ? 'dark' : 'white'}.svg`" />
        </div>
      </template>
      <template v-if="option.componentName === 'Live'">
        <div class="menu__option__label" :class="!showLive ? 'opacityIcon' : 'colorGreen'">
          {{ labelText(option) }}
        </div>
      </template>
      <template v-else>
        <div class="menu__option__label">
          {{ labelText(option) }}
        </div>
      </template>
    </div>
    <Lives :show="showModal" @close="closeModal" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import { goltipsterRightBet, goltipsterParlay, goltipsterTips, goltipsterLive } from '@/utils/analytics';
export default {
  name: 'Menu',
  components: {
    Lives: () => import('@/views/Lives'),
  },
  props: {
    areLiveGames: {
      type: Boolean,
      default: false,
    },
    awaitForLiveGames: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLive: false,
      showModal: false,
      url: 'https://qobetc4ze3.execute-api.us-east-2.amazonaws.com/prod/games/live',
      selectedOptionIndex: 0,
      options: [
        {
          icon: '/icons/icn_right_bet_option',
          label: 'Apuesta Derecha',
          componentName: 'RightBet',
        },
        // {
        //   icon: '/icons/icn_game_bet_option',
        //   label: 'Apuesta por Partido',
        //   labelMobile: 'Apuesta Partido',
        //   componentName: 'GameBet',
        // },
        {
          icon: '/icons/icn_parlay_option',
          label: 'Parlay',
          componentName: 'Parlay',
          precision: '4 Parlays',
        },
        {
          icon: '/icons/icn_golstats_tip_option',
          label: 'Tip Científico',
          componentName: 'Tips',
          precision: '4 Apuestas',
        },
        {
          icon: '/images/menu/icn_live',
          label: 'LIVE',
          componentName: 'Live',
        },
        // {
        //   icon: '/icons/icn_guide_option',
        //   label: 'Guías \nde Uso',
        //   componentName: 'Guide',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapState(['displayWidth']),
    labelText: function() {
      const vm = this;
      return option => {
        return option.labelMobile && vm.displayWidth <= 600 ? option.labelMobile : option.label;
      };
    },
  },
  watch: {
    areLiveGames: {
      immediate: true,
      handler(newValue) {
        this.showLive = newValue;
      },
    },
  },
  methods: {
    onOptionClick(option, index) {
      if (this.selectedOptionIndex !== index) {
        if (option.componentName == 'Live') {
          if (this.showLive) {
            this.showModal = true;
            this.sendEventAnalytics(option.componentName.toLowerCase());
          }
        } else {
          this.selectedOptionIndex = index;
          this.$emit('section-select', option.componentName);
          this.sendEventAnalytics(option.componentName.toLowerCase());
        }
      }
    },
    sendEventAnalytics(name) {
      let names = {
        rightbet: () =>
          goltipsterRightBet({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
        parlay: () =>
          goltipsterParlay({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
        tips: () =>
          goltipsterTips({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
        live: () =>
          goltipsterLive({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
      };
      names[name]();
    },
    isSelectedOption(index) {
      return this.selectedOptionIndex === index;
    },
    async getLivesData() {
      try {
        const { data: games } = await axios
          .get(this.url)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getLivesData();
            }
            return e;
          });
        this.showLive = !!games;
      } catch (e) {
        throw new Error(e);
      }
    },
    openLive() {
      if (this.showLive) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    this.$emit('section-select', this.options[0].componentName);
  },
  async created() {
    if (!this.awaitForLiveGames) {
      await this.getLivesData();
    }
  },
};
</script>

<style scoped lang="scss">
.live__option {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.colorGreen {
  color: #89da69;
}
.opacityIcon {
  opacity: 0.5;
}
.menu {
  width: 100%;
  background-color: #132839;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0.2rem 0.6rem;

  @media screen and (max-width: 500px) {
    border-radius: 12px;
  }

  @media screen and (max-width: 470px) {
    display: grid;
    grid-template-columns: repeat(4, 25%);
  }

  &__option {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    padding: 1.25rem 0 0.95rem;
    color: white;
    position: relative;
    cursor: pointer;

    &:first-child {
      & div.menu__option__label {
        @media screen and (max-width: 348px) {
          margin-top: -3.125rem;
        }
      }
    }

    &__precision {
      position: absolute;
      font-size: 1.125rem;
      font-weight: 600;
      color: white;
      line-height: 1.24;
      letter-spacing: 0.12px;
      top: 0px;
      right: 10px;
      width: fit-content;
      border-radius: 6px;
      background-color: #ed2626;
      padding: 0.05rem 0.74rem;
      white-space: nowrap;
      font-family: 'Roboto-Regular';
      z-index: 1;

      & > span {
        font-size: 1rem;
      }
      @media screen and (max-width: 820px) {
        font-size: 0.9rem;
        width: 90%;
        left: 5%;
      }
      @media screen and (max-width: 768px) {
        text-align: center;
        padding: 0 0 0;
      }
      @media screen and (max-width: 690px) {
        right: 0;
        font-size: 0.813rem;
      }
      @media screen and (max-width: 615px) {
        font-size: 0.713rem;
      }
      @media screen and (max-width: 580px) {
        font-size: 0.705rem;
        top: -2px;
      }
      @media screen and (max-width: 490px) {
        font-size: 0.695rem;
      }
      @media screen and (max-width: 470px) {
        font-size: 0.688rem;
      }
      @media screen and (max-width: 440px) {
        display: none;
      }
    }

    &__label {
      margin-left: 0.5rem;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
      padding-bottom: 0.4rem;
      text-align: center;
    }

    &--active {
      background-color: #cbee6b;
      border-radius: 18px;
      color: #132839;
    }
    @media screen and (max-width: 820px) {
      padding: 1.5rem 1.4rem 1.2rem;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: space-between;
      padding: 0.3rem 2.9rem 0;
      font-size: 0.9rem;
      &__label {
        width: fit-content;
      }
    }
    @media screen and (max-width: 690px) {
      padding: 0.3rem 2.3rem 0;
    }
    @media screen and (max-width: 615px) {
      padding: 0.3rem 1.9rem 0;
    }

    @media screen and (max-width: 580px) {
      padding: 0.3rem 1.5rem 0;
    }
    @media screen and (max-width: 580px) {
      padding: 0.3rem 1.2rem 0;
    }

    @media screen and (max-width: 490px) {
      border-radius: 10px;
      &__label {
        text-align: center;
        font-size: 0.8rem;
      }
    }
    @media screen and (max-width: 350px) {
      &__label {
        white-space: pre-wrap;
      }
    }
  }
}
</style>
